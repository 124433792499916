import React, {Component} from 'react';
import GenericListContainer from "../GenericList/GenericListContainer";
import {withRouter} from "react-router-dom";
import {
  CODEDETAILS,
  GET_LOGIN_DATA,
  GET_ORG_VERSION,
  GET_PROJECTSBYSTRUCTUREYEAR
} from "../GraphQl/query";
import {ERRORS, SUBCATEGORIESTOTALS} from "../../navigation/Routes";
import {Query} from "@apollo/client/react/components";
import {withApollo} from "@apollo/client/react/hoc";
import {ROLES_TO_SEE_STATUS} from "../Helpers/constants";
import {ProjectContext} from "../ProjectProvider";
import {Spinner} from "reactstrap";
import {useQuery} from "@apollo/client";






function getProjectList(list, role) {

    return list ? list.organizationVersions.map(items => ({
        ...items.version.project,
        status: items.status,
        instituteDescription: ROLES_TO_SEE_STATUS.includes(role)?items.organization.description:items.version.project.instituteDescription,
        projectId: items.version.project.id,
        externalProjectId: items.version.project.projectId,
        isVerificationFailed: !!items.orgVersionValidations.find(item => item.validationResult === "WARNING" || item.validationResult === "ERROR"),
        lastModifiedDate: items.lastModifiedDate,
        parent: items.parent,
        orgVersionId: items.id,
        organizationId: items.organization.id,
        orgVersionInstCode:items.organization.instituteCode,
        structureId: items.version.project.structure.id,
        structureInfo: items.version.project.structure,
        startingDate: items.version.startPeriodDate,
        endingDate: items.version.endPeriodDate,
        description: items.version.description
    })).map(({__typename, structure, ...item}) => item) : list;
}

class ProjectsByStructureYear extends Component {
    static contextType = ProjectContext;
  
  
  // const code = this.props.location.state?.code;
  
  //const {loading, error, result } =  useQuery(CODEDETAILS,  { variables: { code: "RF-2016-02363730" } });
    

    componentDidMount() {
        this.context.setProject('');
    }

    getVariables = (auth) => {
        const role = auth.userInfo.role;
        if (role === "ADMIN" || role === "MOH")
            return null;
        else if (role === "PROJECT" || role === "USER")
            return {instituteCode: auth.userInfo.instituteCode};
    };

    toSubCategoryList = (orgVersionId, structureId, project) => {
        //debugger;
        this.props.history.push({
            pathname: `${SUBCATEGORIESTOTALS}/${orgVersionId}/${structureId}`,
            state: {
                status: project.status,
                isCloned: !!project.parent,
                projectCode: project.code,
                projectId: project.id,
                isExternal: project.isExternal,
                projectYear: project.year,
                externalProjectId: project.externalProjectId,
                organizationId: project.organizationId,
                structureInfo: project.structureInfo,
                orgVersionInstCode: project.orgVersionInstCode,
                coFinancing: project.coFinancing,
                instituteDescription: project.instituteDescription
            }
        })
    };

    toErrorList = (orgVersionId) => {
        this.props.history.push(`${ERRORS}/${orgVersionId}`)
    };
    
    
    
    

    render() {
      
      
      
        // QUESTA FUNZIONE È DA TRASHARE
        
      
        const refetch = this.props.location.state?.refetch;
      
      
        const code = this.props.location.state?.code;
      
        console.log("here is the code: ", code);
        
        console.log("::this.props x", this.props);
      
      
      
        console.log("................................: ");
        
        
        
        
        
        const { structureId, year } = this.props.match.params;
        
        
        console.log("<structureId: ", structureId);
        console.log("<year: ", year);
        
        
        if (code === undefined)
        
        return (
            <Query query={GET_LOGIN_DATA}>
                
                {({data: auth}) => (
                  
                    <Query query={GET_ORG_VERSION} variables={{structureId: structureId, year: year}} fetchPolicy={refetch ? 'network-only' : 'cache-first'}>
                        
                      {({loading, error, data: list}) => {
                            
                            if (loading) return <Spinner style={{width: '3rem', height: '3rem'}}/>;
                            
                            console.log("list: ", list);
                            
                            if (error) return error.message;
                            
                            let projectList;
                          
                            const role = auth? auth.userInfo.role:""
                            
                            //projectList = getProjectList(list, role).sort((a, b) => b.orgVersionId - a.orgVersionId);
                        
                            const instCode = this.getVariables(auth);
                            
                            return (
                                <GenericListContainer list={list.result} structureName={"Project"}
                                                      btnLabel={"Create Project"}
                                                      parentValue={"Progetti"}
                                                      linkFunction={this.toSubCategoryList}
                                                      detailsBtnLabel={"Categorie"}
                                                      secondLink={this.toErrorList}
                                                      instCode={instCode}
                                />
                            )
                        
                        }}
                      
                    </Query>
                )}
              
            </Query>
        )
      
      
      else
          
          return (
            
            <Query query={GET_LOGIN_DATA}>
              
              {({data: auth}) => (
                
                
                
                
                <Query query={CODEDETAILS}  variables= {{ code: code } }>
                  
                  {({data: codedetails}) => (
                    
                    
                  
                
                
                
                <Query query={GET_ORG_VERSION} variables={{structureId: structureId, year: year}} fetchPolicy={refetch ? 'network-only' : 'cache-first'}>
                  
                  {({loading, error, data: list}) => {
                    
                    if (loading) return <Spinner style={{width: '3rem', height: '3rem'}}/>;
                    
                    console.log("list: ", list);
                    
                    
                    
                    console.log("codedetails: ", codedetails);
                    
                    if (error) return error.message;
                    
                    let projectList;
                    
                    const role = auth? auth.userInfo.role:""
                    
                    //projectList = getProjectList(list, role).sort((a, b) => b.orgVersionId - a.orgVersionId);
                    
                    const instCode = this.getVariables(auth);
                    
                    return (
                      <GenericListContainer list={list.result} structureName={"Project"}
                                            btnLabel={"Create Project"}
                                            parentValue={"Progetti"}
                                            linkFunction={this.toSubCategoryList}
                                            detailsBtnLabel={"Categorie"}
                                            secondLink={this.toErrorList}
                                            instCode={instCode}
                      />
                    )
                    
                  }}
                
                </Query>
                    
              )}
                  
                  
                
                </Query>
              )}
            
            </Query>
          )  
        
        
      
      
    }
}

export default withApollo(withRouter(ProjectsByStructureYear));
