import {gql} from '@apollo/client';

export const GET_STRUCTURE = gql`
    query structure($structureName: String!){
        __type(name: $structureName) {
            name
            structure: fields {
                Header: name
                accessor: name
                Header: name
                accessor: name
                type {
                    name
                    kind
                    ofType {
                        name
                        kind
                    }
                }
            }
        }
    }`;

export const GET_CATEGORIES = gql`
    query categories($id: Int){
        result: categories(id:$id)
        {
            id
            description
            createdDate
            lastModifiedDate
            active
        }
    }`;

export const getsubcategory2 = gql`query subcategories($subCategoryId:Int $id:Int $activ:Boolean){
    result: subcategories(
        subCategoryId:$subCategoryId
        categoryId:$id
        active: $activ) {
        id description active category{id description}
    }
}`;

export const GET_FINANCIAL = gql`query getFinancials($id:Int! $orgVersion:Int!){
    result:financials(modelId:$id orgVersionId:$orgVersion active:true){

        accessor rowId id value model{id description}  organizationVersion{id}
    }
}`;

export const GET_MODELS = gql`query models($modelId:Int $id:Int){
    result: models(modelId:$modelId subcategoryId: $id) {
        id description  subcategory{id description} items active uploadEnabled
    }
}`;

export const GET_FINANCIALITEM = gql`query models($id:Int $subcategoryId:Int){
    models: models(modelId:$id subcategoryId: $subcategoryId) {
        items
    }
}`;

export const GET_LOGIN_DATA = gql`
    query {
        isLoggedIn @client
        userInfo @client{
            id
            instituteCode
            role
            name
            surname
        }
    }
`;
export const GET_STRUCTURE_INFO = gql`
    query {
        structInfo @client {
            financialsApi
            hasUnits
        }
    }
`;
export const GET_EQUIPMENT = gql`
query equipments
( $id: Int, $code: String)
{
  result:equipments
     (id: $id, code: $code)
  {
    code
    description
  }
}
`;

export const GET_ORG_VERSION = gql`
        query organizationVersions
    ($instituteCode: String, $active: Boolean $projectVersionId: Int, $structureId: Int, $year: Int)
    {
        organizationVersions
        (instituteCode: $instituteCode, active: $active projVersionId: $projectVersionId, structureId: $structureId, year: $year)
        {
         id status  parent lastModifiedDate  
         orgVersionValidations{
         validationResult
         }
         organization{id, description, instituteCode, isExternal}
         version{
          id active startPeriodDate endPeriodDate description
          project {
                    id
                    projectId
                    code year title 
                    startingDate endingDate description extensionDate 
                    typeCode coFinancing structure{id}       
                    instituteCode instituteDescription
                    structure {
                        id 
                        financialsApi 
                        hasUnits 
                        utcDeadline
                        description 
                        model{id}
                    }

                }
            }
         organization {
                id
                
            }
        }
    }
`;


export const GET_PROJECTSBYSTRUCTUREYEAR = gql`
  query projectsbystructureyear
  ($structureId: Int $year:Int )
  {
    result:projectsbystructureyear
    (structureId:$structureId year:$year)
    {
      id projectId code year title description instituteDescription startingDate
      structure{id description} projectVersions{id} manager{id} organizations{id}
      lastModifiedDate instituteCode coFinancing typeCode extensionDate endingDate
    }
  }`;


export const GET_PROJECT = gql`
 query projects
($managerId: Long $projectId:Int $structureId: Int)
{
    result:projects
    (managerId: $managerId projectId:$projectId structureId:$structureId)
    {
        id projectId code year title description instituteDescription startingDate
      structure{id description} projectVersions{id} manager{id} organizations{id}
      lastModifiedDate instituteCode coFinancing typeCode extensionDate endingDate 
    }
}`;
export const FIND_PROJECT = gql`
 query findProject
($projectId:Int)
{
    result:findProject
    (projectId:$projectId)
    {
        id projectId code year title description instituteDescription startingDate
      structure{id description hasUnits hasInterim} projectVersions{id} manager{id} organizations{id}
      lastModifiedDate instituteCode coFinancing typeCode extensionDate endingDate 
    }
}`;


export const GET_PROJECT_VERSION = gql`query projectsVersion
($projectId:Int $projectVersionId:Int)
{
    result:projectVersions
    (projectId:$projectId projectVersionId: $projectVersionId)
    {
       id  active project{id code structure{financialsApi hasUnits hasInterim}} description  startPeriodDate endPeriodDate versionType
    }
}`;

// organization{ description id instituteCode }
export const GET_PROJECT_UNITS = gql`query getUnits
($projectId:Int)
{
    result:getUnits
    (projectId:$projectId)
    {
        id 
        organization{ 
            description 
            id 
            instituteCode 
            isExternal 
            fiscalCode
            # user {
            #     id
            # }
        } 
        coFinancing 
        cup
    }
}`;
export const GET_ORGANIZATION = gql`
query findOrganization
($fiscalCode:String)
{
   result:findOrganization
   (fiscalCode:$fiscalCode)
   {
       id instituteCode fiscalCode description longDescription legalRepresentativeName legalRepresentativeSurname isExternal
   }
}`;


export const GET_SUBCATEGORY_TOTALS = gql`
    query subcategoryTotals
    ($organizationVersionId: Int, $structureId: Int)
    {
        subcategoryTotals
        (organizationVersionId: $organizationVersionId, structureId: $structureId)
        {
            structureId
            organizationVersionId
            subcategoryId
            modelId
            description
            sumTotal
            accessor
            status
        }
    }
`;

export const ORG_VERSION_MANAGER = gql`
query organizationVersionsManager
    ($projVersion:Int)
    {
         organizationVersionsManager
        (projVersionId: $projVersion)
        {
          id 
          status
          lastModifiedDate
          parent
          version{
            id
            project{
              id
              description
              code 
              year 
              title 
              description 
              startingDate 
              endingDate 
              extensionDate 
              typeCode 
              coFinancing    
              instituteCode 
              instituteDescription 
              projectId
              structure{
                id 
                financialsApi 
                hasUnits
              }
            }
          }
          organization{
            description
            id
            instituteCode    
            isExternal
          }
        }
    }`;

export const MODEL_DESCR = gql`query getFinancialsModelDesc($modelId: Int!) {
    models(modelId: $modelId) {
        description, uploadEnabled
    }
}`;

export const RESEARCHERS = gql`query researchersFromApi
($year: Int!)
{
    result:researchersFromApi
    (year: $year)
    {
        name
        surname
        fiscalCode
    }
}`;


export const CODEDETAILS = gql`query projects
($code: String!)
{
  result:codedetails
  (code: $code)
  {
    year
    structure {description, id}
  }
}`;


export const RESEARCHERSV2 = gql`query researchersV2FromApi
($instituteCode: Int! $projectCode: Int!)
{
    result:researchersV2FromApi
    (instituteCode: $instituteCode projectCode:$projectCode)
    {
        name
        surname
        fiscalCode
    }
}`;

export const GET_PRJ_INFO = gql`
    query {
        year @client
        projectId @client
        externalProjectId @client
    }`;

export const GET_PRJ_VERSION_INFO = gql`
    query {
         projectVersionDesc @client
         hasUnits @client
    }`;

export const GET_UNITS_LIST = gql`
    query {
       result @client
    }`;

export const GET_ERRORS = gql`
query validationErrors
($orgVersionId: Int)
{
  validationErrors
  (orgVersionId: $orgVersionId)
  {
    organizationVersion {id}
    validationResult
    validationMessage
    active  
    validationRule {id message expression accessedDate}
  }
}`;

export const GETFINANCIALSTATUS = gql`query financialsStatuses
($rowId: String, $modelId: Int!, $orgVersion: Int!)
{
    result:financialsStatuses
    (rowId:$rowId, modelId:$modelId, organizationVersionId: $orgVersion)
    {
        rowId
        comment
        status
        userComment
        attachmentName
        attachmentPath
        reasonCode{code description id}
        __typename
    }
}`;

export const GETREASONCODE = gql`query getReasonCode($reasonCodeId:Int)
{
    result:reasonCodes(reasonCodeId:$reasonCodeId)
    {
        id
        code
        description
        __typename
    }
}`;

export const GETSUBCATEGORYCOMMENT = gql`query subcategoryComment($commentId:Int $modelId:Int, $organizatoinVersionId: Int, $subCategoryId: Int)
{
    result: subcategoryComments(subCategoryCommentId:$commentId,modelId:$modelId, organizationVersionId:$organizatoinVersionId, subCategoryId:$subCategoryId)
    {
        id
        comment
        model{id}
        organizationVersion{id}
    }
}`;

export const GET_STRUCTURE_QUERY = gql`
    query structures
    {
        result:structures
        {
            id
            description
            validationRules{message}
            projects{
              id
              year
              title
              instituteCode
            }
            hasUnits
            deptDescription
            xlsVersion
            financialsApi
        }
    }
`;

export const GET_STRUCTURE_MOH = gql`
    query structures
    {
        result:structures
        {
            id
            description
            projects{
                id
                projectVersions{
                    active
                  organizationVersions{
                    id
                    status
                  }
                }
            }
        }
    }
`;

export const GET_STRUCTURE_SUBCATEGORY = gql`query subcategories($structureId:Int){
    result: structuresSubcategories(
        structureId:$structureId
       ) {
        id
        structure{description hasUnits financialsApi}
        subcategory{id description}
        model{id description}
        active
        numrow
    }
}
`;
export const RULES = gql`
    query verificationRule($structureId:Int)
    {
        result:validationRules(structureId:$structureId)
        {
            id
            message
            expression
            active
            validationResult
        }
    }
`;
export const GET_UNITS = gql`
query getUnits($projectId:Int!){
  result: getUnits(projectId:$projectId){id coFinancing organization{id description}}
}`;

export const GET_USER_QUERY = gql`
    query getUsers($instituteCode: String!)
    {
        result: getUsers(instituteCode: $instituteCode)
        {
            id
            email
            name
            surname
        }
    }
`;


// export const GET_BUDGETS_QUERY = gql`
//   query budgets($id:Int)
//   {
//     result: budgets(id: $id)
//     {
//       id
//       project_id
//       model_id
//       budget
//     }
//   }
// `;

export const GET_BUDGETS_QUERY = gql`
  query budgetsByProjectId($prj_id:Int!)
  {
    result: budgetsByProjectId(prj_id: $prj_id)
    {
      id
      budget
      model {
        id
        description
      }
      project {
        id
        projectId
        title
        description
        year
      }
    }
  }
`;

export const GET_BUDGET_TEMPLATE = gql`
  query findProject($projectId:Int)
  {
    result: findProject(projectId: $projectId)
    {
      projectId
      year
      title
      structure {
        id
        description
        structureSubcategories {
          active
          numrow
          subcategory {
            id
            description
            category {
              id
            }
            models {
              id
            }
          }
        }
      }
      code
      id
    }
  }
`;


export const GET_BUDGET_TEMPLATE_S = gql`
  query findProject($projectId:Int)
  {
    projectBudgets(projectId: $projectId) {
      id
      budget
      model {
        id
        description
        subcategory {
          id
          description
          category {
            id
            description
          }
        }
      }
      project {
        id
        projectId
        code
        year
        instituteDescription
        cup
        instituteCode
        title
      }
    }
  }
`;
