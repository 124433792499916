import React, {Component} from 'react';
import {Query} from "@apollo/client/react/components";
import {GET_FINANCIAL, GET_PRJ_INFO, GET_UNITS, GETFINANCIALSTATUS, MODEL_DESCR} from "../GraphQl/query";
import GenericListContainer from "../GenericList/GenericListContainer";
import {withRouter} from "react-router-dom";
import {isNumber} from "../Helpers/calculationFunctions";
import {duplicateResponse} from "../Helpers/functions";
import {Spinner} from "reactstrap";


class FinancialContainer extends Component {

    prepareFinancialList = (data, statuses, units) => {
        const financialList = data.reduce(function (acc, row) {
            const found = acc.find(a => a.rowId === row.rowId);
            if (found) {
                found[row.accessor] = row.value
            } else {
                const r = {...row};
                r[r.accessor] = r.value;
                delete r.accessor;
                delete r.value;
                acc.push(r)
            }
            return acc;
        }, []);
        if (statuses && statuses.length > 0) {
            return financialList.map(finItem => {
                const status = statuses.find(statusItem => statusItem.rowId === finItem.rowId);
                if (status) {
                    if (status.hasOwnProperty('reasonCode') && typeof status.reasonCode === 'object' && status.reasonCode !== null) {
                        const {code, description, id} = status.reasonCode;
                        status.reasonCode = code;
                        status.description = description;
                        status.reasonCodeId = id
                    }
                }
              
                // Fix ???
                // const mergedObject = {
                //   ...status,
                //   ...finItem,
                //   ["status"]: status["status"], // Destruttura e riassegna
                // };
                // return mergedObject
              
                return {...status, ...finItem}
              
            });
        } else {
            return financialList
        }
    };

    prepareUnitList = (financialList, unitList) => {
        if (unitList && unitList.length > 0) {
            return financialList.map(finItem => {
                const unit = unitList.find(unitItem => unitItem.id === parseInt(finItem.unit));
                const description = unit ? unit.organization.description : null;
                if (description) {
                    return {...finItem, unit: description}
                }
                return finItem
            });
        } else return financialList
    }

    getTotals(list, col) {
        return list
            .filter(item => item.status !== 'REJECTED')
            .map(item => isNumber(item[col]))
            .reduce((acc, red) => (acc + red), 0);
    }

    getPreviousVersion(list) {
        // eslint-disable-next-line eqeqeq
        return list.filter(item => item.previous_version == 1)
    }

    render() {
        const modelId = parseInt(this.props.match.params.id);
        const orgVersion = parseInt(this.props.match.params.orgVersionId);
        const status = this.props.history.location.state.status;
        const isCloned = this.props.history.location.state.isCloned;
        const structureId = this.props.history.location.state.structureId;
        const isExternal = this.props.history.location.state.isExternal;
        return (
            <Query query={GET_PRJ_INFO}>
                {({data: projectInfo, loading: loadingInfo}) => (
                    !loadingInfo && <Query query={GET_UNITS} variables={{projectId: projectInfo?.projectId}}>
                        {({data: unitsList, loading: loadingUnits}) => (
                            <Query query={MODEL_DESCR} variables={{modelId: modelId, orgVersion: orgVersion}}>
                                {({data: modelDescr, loading: load}) => (
                                    !load &&
                                    <Query query={GETFINANCIALSTATUS}
                                           variables={{modelId: modelId, orgVersion: orgVersion}}>
                                        {({data: finStatus, loading: loadFinStatus}) => (
                                            !loadFinStatus && <Query
                                                query={GET_FINANCIAL} variables={{id: modelId, orgVersion: orgVersion}}
                                                fetchPolicy="cache-and-network">
                                                {({loading, error, data}) => {
                                                    if (loading || loadingInfo || loadingUnits || loadFinStatus) return <Spinner style={{width: '3rem', height: '3rem'}}/>;
                                                    if (error) return <div>{error.message}</div>;
                                                    const clonedList = duplicateResponse(data.result)
                                                    const clonedFinStatuses = duplicateResponse(finStatus.result)
                                                    const list = this.prepareFinancialList(clonedList, clonedFinStatuses, unitsList);
                                                    const finList = this.prepareUnitList(list, unitsList?.result)
                                                    const total_cost = this.getTotals(list, 'total_cost');
                                                    const eligible_cost = this.getTotals(list, 'eligible_cost');
                                                    const previousList = this.getPreviousVersion(list);
                                                    const total_previous_cost = this.getTotals(previousList, 'total_cost');
                                                    const eligible_previous_cost = this.getTotals(previousList, 'eligible_cost');
                                                    const parentName = modelDescr.models[0] ? modelDescr.models[0].description : '';
                                                    const isUploadEnabled = modelDescr.models[0] ? modelDescr.models[0].uploadEnabled : false;
                                                    return (
                                                        <GenericListContainer list={finList}
                                                                              totalCost={total_cost}
                                                                              totalCurrentCost={total_cost - total_previous_cost}
                                                                              eligibleCost={eligible_cost}
                                                                              eligibleCurrentCost={eligible_cost - eligible_previous_cost}
                                                                              structureName={"Financial"}
                                                                              structureId={structureId}
                                                                              parentName={"Model"}
                                                                              parentValue={parentName}
                                                                              isUploadEnabled={isUploadEnabled}
                                                                              btnLabel={"Inserisci"}
                                                                              status={status}
                                                                              isCloned={isCloned}
                                                                              isExternal={isExternal}
                                                        />
                                                    )
                                                }}
                                            </Query>
                                        )}
                                    </Query>
                                )}
                            </Query>
                        )}
                    </Query>
                )}
            </Query>
        )
    }
}

export default withRouter(FinancialContainer);

