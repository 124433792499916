import React, {Component} from 'react';
import {Query} from "@apollo/client/react/components";
import i18n from "i18next";
import {
    GET_PRJ_INFO,
    GET_STRUCTURE_INFO,
    GET_SUBCATEGORY_TOTALS,
    GET_UNITS,
    GET_UNITS_LIST,
    GET_LOGIN_DATA
} from "../GraphQl/query";
import GenericListContainer from "../GenericList/GenericListContainer";
import {withRouter} from "react-router-dom";
import {FINANCIAL} from "../../navigation/Routes";
import {isNumber} from "../Helpers/calculationFunctions";
import {withApollo} from "@apollo/client/react/hoc";
import {ProjectContext} from "../ProjectProvider";
import { APPROVED, CONSOLIDATED, SNAPSHOTTED, SUBMITTED, VERIFIED, ADMIN, MOH } from '../Helpers/constants';
import { Spinner } from 'reactstrap';


class SubcategoryTotals extends Component {
    static contextType = ProjectContext;
    organizationVersionId = parseInt(this.props.match.params.orgVersionId);
    //organizationVersionId = 4924;
    status = this.props.history.location.state.status;
    isCloned = this.props.history.location.state.isCloned;
    isExternal = this.props.history.location.state.isExternal;
    orgVersionInstCode = this.props.location.state.orgVersionInstCode;
    consolidatedStatuses = [i18n.t(CONSOLIDATED), i18n.t(SNAPSHOTTED),  i18n.t(SUBMITTED), i18n.t(VERIFIED), i18n.t(APPROVED)];
    
    componentDidMount() {
        //console.log(this.isExternal);
        this.context.setProject(`${this.props.location.state.projectCode}\n${this.props.location.state.instituteDescription}`);
    }

    prepareTotals = (data) => {
        return data.subcategoryTotals.reduce(function (acc, row) {
            const found = acc.find(a => a.subcategoryId === row.subcategoryId);
            if (found) {
                if (row.accessor) {
                    found[row.accessor] = row.sumTotal;
                    found.structureId = row.structureId;
                }
            } else {
                const r = {...row};
                if (row.accessor) r[r.accessor] = r.sumTotal;
                delete r.accessor;
                delete r.sumTotal;
                acc.push(r)
            }
            return acc;
        }, []);
    };

    addPercToList = (list, totalCost, eligibleCost) => {
        return list.map(row => {
            row.total_cost_perc = !isNaN(row.total_cost) && totalCost > 0 ? (row.total_cost / totalCost * 100).toFixed(2) : null;
            row.eligible_cost_perc = !isNaN(row.eligible_cost) && eligibleCost > 0 ? (row.eligible_cost / eligibleCost * 100).toFixed(2) : null;
            return row;
        });
    };

    toFinancial = (modelId, structureId) => {
        //debugger;
        this.props.history.push({
            pathname: `${FINANCIAL}/${modelId}/${this.organizationVersionId}`,
            state: {isExternal: this.isExternal, status: this.status, isCloned: this.isCloned, structureId:structureId, orgVersionInstCode:this.orgVersionInstCode}
        })
    };

    filterUnits = (units, organizationId) => {
        if (units)
            return units.result.filter(unit => unit.organization.id === organizationId)
    }

    getCoFinancial = (unitLis, projCoFinancing) => {
        const canBeConsolidated = this.consolidatedStatuses.includes(this.status);
        if (unitLis?.length > 0 && !canBeConsolidated) return unitLis[0].coFinancing;
        return projCoFinancing
    }

    getTotals(list, col) {
        return list.map(item => isNumber(item[col])).reduce((acc, red) => (acc + red), 0);
    }

    filterStatusList(list, projectStatus, auth) {
        const userRole = auth ? auth.userInfo.role : "";
        if (projectStatus === i18n.t(SUBMITTED) && ![ADMIN,MOH].includes(userRole)) {
            return list.map(item => ({...item, status: undefined}));
        }
        return list
    }

    render() {
      

        //console.log("organizationVersionId: ", this.organizationVersionId);
        
        //console.log("SubcategoryTotals");
        //console.log(this.props.history.location.state);
      
        const structureId = parseInt(this.props.match.params.structureId);
        const projectCode = this.props.location.state.projectCode;
        const projectStatus = this.props.location.state.status;
        const projectYear = this.props.location.state.projectYear;
        const projectId = this.props.location.state.projectId;
        const isExternal = this.props.location.state.isExternal;
        const externalProjectId = this.props.location.state.externalProjectId;
        const organizationId = this.props.location.state.organizationId;
        const structureInfo = this.props.location.state.structureInfo;
        const projCoFinancing = this.props.location.state.coFinancing;
        return (
            <Query query={GET_LOGIN_DATA}>
            {({data: auth}) => (<Query query={GET_UNITS} variables={{projectId: projectId}}>
                {({data: units}) => (
                    <Query
                        query={GET_SUBCATEGORY_TOTALS} variables={{
                        organizationVersionId: this.organizationVersionId,
                        structureId: structureId
                    }} fetchPolicy="network-only">
                        {({loading, error, data}) => {
                            if (loading) return <Spinner style={{width: '3rem', height: '3rem'}}/>;
                            if (error) return <div>{error.message}</div>;
                            const list = this.prepareTotals(data);
                            const total_cost = this.getTotals(list, 'total_cost');
                            const eligible_cost = this.getTotals(list, 'eligible_cost');
                            const total_current_cost = this.getTotals(list, 'current_total_cost');
                            const eligible_current_cost = this.getTotals(list, 'current_eligible_cost');
                            const newList = this.addPercToList(list, total_cost, eligible_cost);
                            let filteredStatuslist = this.filterStatusList(newList, projectStatus, auth);
                            // spreadare la propieta isExternal e deve potere essere utilizzzabile nello header
                            // in http://localhost:8030/financial/51/3922
                          
                            // Forse Non Serve
                            // filteredStatuslist = filteredStatuslist.map(status => ({
                            //   ...status,
                            //   isExternal: true
                            // }));
                            // debugger;
                            const unitList = this.filterUnits(units, organizationId);
                            const coFinancig = this.getCoFinancial(unitList, projCoFinancing);
                            this.props.client.writeQuery({
                                query: GET_PRJ_INFO,
                                data: {
                                    year: projectYear,
                                    projectId: projectId,
                                    externalProjectId: externalProjectId
                                }
                            });
                            this.props.client.writeQuery({
                                query: GET_UNITS_LIST,
                                data: {
                                    result: unitList
                                }
                            });
                            this.props.client.writeQuery({
                                query: GET_STRUCTURE_INFO,
                                data: {
                                    structInfo: structureInfo
                                }
                            });
                            return (
                                <GenericListContainer list={filteredStatuslist}
                                                      isExternal={isExternal}
                                                      structureId={structureId}
                                                      coFinancig={coFinancig}
                                                      structureName={"SubCategoryTotalDto"}
                                                      totalCost={total_cost}
                                                      totalCurrentCost={total_current_cost}
                                                      eligibleCost={eligible_cost}
                                                      eligibleCurrentCost={eligible_current_cost}
                                                      parentValue={projectCode}
                                                      btnLabel={"Create SubCategory"}
                                                      detailsBtnLabel={"Dettaglio"}
                                                      linkFunction={this.toFinancial}
                                />
                            )
                        }}
                    </Query>
                )}
            </Query>)}
            </Query>
        )
    }
}

export default withApollo(withRouter(SubcategoryTotals));
