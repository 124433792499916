import React, {Component} from 'react';
import GenericListContainer from "../GenericList/GenericListContainer";
import {withRouter} from "react-router-dom";
import {GET_LOGIN_DATA, GET_ORG_VERSION} from "../GraphQl/query";
import {ERRORS, SUBCATEGORIESTOTALS} from "../../navigation/Routes";
import {Query} from "@apollo/client/react/components";
import {withApollo} from "@apollo/client/react/hoc";
import {ROLES_TO_SEE_STATUS} from "../Helpers/constants";
import {ProjectContext} from "../ProjectProvider";
import {Spinner} from "reactstrap";


function getProjectList(list, role) {

    return list ? list.organizationVersions.map((items, index) => ({
        ...items.version.project,
        status: items.status,
        instituteDescription: ROLES_TO_SEE_STATUS.includes(role)?items.organization.description:items.version.project.instituteDescription,
        projectId: items.version.project.id,
        externalProjectId: items.version.project.projectId,
        isVerificationFailed: !!items.orgVersionValidations.find(item => item.validationResult === "WARNING" || item.validationResult === "ERROR"),
        lastModifiedDate: items.lastModifiedDate,
        parent: items.parent,
        orgVersionId: items.id,
        organizationId: items.organization.id,
        isExternal: items.organization.isExternal,
        orgVersionInstCode:items.organization.instituteCode,
        structureId: items.version.project.structure.id,
        structureInfo: items.version.project.structure,
        startingDate: items.version.startPeriodDate,
        endingDate: items.version.endPeriodDate,
        description: items.version.description
    })).map(({__typename, structure, ...item}) => item) : list;
}

class ProjectList extends Component {
    static contextType = ProjectContext;

    componentDidMount() {
        this.context.setProject('');
    }

    getVariables = (auth) => {
        const role = auth.userInfo.role;
        if (role === "ADMIN" || role === "MOH")
            return null;
        else if (role === "PROJECT" || role === "USER")
            return {instituteCode: auth.userInfo.instituteCode};
    };

    toSubCategoryList = (orgVersionId, structureId, project) => {
        this.props.history.push({
            pathname: `${SUBCATEGORIESTOTALS}/${orgVersionId}/${structureId}`,
            state: {
                status: project.status,
                isCloned: !!project.parent,
                projectCode: project.code,
                projectId: project.id, 
                isExternal: project.isExternal,
                projectYear: project.year,
                externalProjectId: project.externalProjectId,
                organizationId: project.organizationId,
                structureInfo: project.structureInfo,
                orgVersionInstCode: project.orgVersionInstCode,
                coFinancing: project.coFinancing,
                instituteDescription: project.instituteDescription
            }
        })
    };

    toErrorList = (orgVersionId) => {
        this.props.history.push(`${ERRORS}/${orgVersionId}`)
    };

    render() {
      
        const refetch = this.props.location.state?.refetch;
        
        let { structureId, year } = this.props.match.params;
        let code = this.props.location.state?.code;
        let prj_desc = this.props.location.state?.prj_desc;
      
    
        
        return (
          <Query query={GET_LOGIN_DATA}>
            
            {({data: auth}) => (
              
              <Query query={GET_ORG_VERSION} variables={{structureId: structureId, year: year}} fetchPolicy={refetch ? 'network-only' : 'cache-first'}>
                
                {({loading, error, data: list}) => {
                  
                  if (loading) return <Spinner style={{width: '3rem', height: '3rem'}}/>;
                  
                  if (error) return error.message;

                  let projectList;
                  
                  const role = auth? auth.userInfo.role:""
                  
                  projectList = getProjectList(list, role).sort((a, b) => b.orgVersionId - a.orgVersionId);
                  
                  const instCode = this.getVariables(auth);
                  
                  return (
                    <GenericListContainer list={projectList} structureName={"Project"}
                                          btnLabel={"Create Project"}
                                          parentValue={"Progetti"}
                                          linkFunction={this.toSubCategoryList}
                                          detailsBtnLabel={"Categorie"}
                                          secondLink={this.toErrorList}
                                          code={code}
                                          instCode={instCode}
                                          prj_desc={prj_desc}
                    />
                  )
                  
                }}
              
              </Query>
            )}
          
          </Query>
        )
      
    }
}
export default withApollo(withRouter(ProjectList));
